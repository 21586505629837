<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>
      <form v-on:submit.prevent="onSubmitted">
        <div class="row">

          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Emp Code</label>
              <input
                type="text"
                class="form-control"
                v-model="user.emp_code"
                placeholder="Enter Emp Code"
                required
              />
            </div>
          </div>

          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>First Name</label>
              <input
                type="text"
                class="form-control"
                v-model="user.first_name"
                placeholder="Enter user first name"
                required
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Last Name</label>
              <input
                type="text"
                class="form-control"
                v-model="user.last_name"
                placeholder="Enter user last name"
                required
              />
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label>Email</label>
              <input
                type="email"
                class="form-control"
                v-model="user.email"
                placeholder="Email address"
                required
              />
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label>Mobile Number</label>
              <input
                type="text"
                class="form-control"
                v-model="user.mobile_number"
                placeholder="Mobile number"
                required
              />
            </div>
          </div>

          <div class="col-md-12" v-if="roles.length">
            <div class="form-group">
              <label>Assign Roles</label>

              <div class="checkbox" v-for="role in roles" :key="role.id">
                <label
                  ><input
                    type="checkbox"
                    v-model="user.roles"
                    :value="role.id"
                  />
                  {{ role.display_name }}</label
                >
              </div>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </CCol>
  </CRow>
</template>

<script>
import { flagSet } from "@coreui/icons";
import { mapGetters } from "vuex";

export default {
  name: "CreateUser",
  data: () => {
    return {
      showToast: false,
      toastr: "",
      user: {
        emp_code: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        roles: [],
      },
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  methods: {
    onSubmitted() {
      this.$store
        .dispatch("Users/storeUser", this.user)
        .then(() => {
          this.showToast = true;
          this.$toastr.s('success', "User successfully created.", "User Created");
          this.$emit("onSavedUser");
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.serverErrors = [];
          if (err.response.data.message) {
            this.serverErrors.push(err.response.data.message);
          }

          let errors = err.response.data.errors;
           this.showToast = true;
          for (let field of Object.keys(errors)) {
            this.$toastr.e("error", errors[field][0], "Error!");
          }
        });
    },
  },
  computed: {
    ...mapGetters("Roles", ["roles"]),
  },
  mounted() {
    this.$store.dispatch("Roles/getRoles");
  },
};
</script>

<style scoped>
</style>
