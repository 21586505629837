<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>
      <form v-on:submit.prevent="onSubmitted">
        <div class="row">
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Emp Code</label>
              <input
                type="text"
                class="form-control"
                v-model="edited_user.emp_code"
                placeholder="Enter emp code"
                required
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>First Name</label>
              <input
                type="text"
                class="form-control"
                v-model="edited_user.first_name"
                placeholder="Enter user first name"
                required
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Last Name</label>
              <input
                type="text"
                class="form-control"
                v-model="edited_user.last_name"
                placeholder="Enter user last name"
                required
              />
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label>Email</label>
              <input
                type="email"
                class="form-control"
                v-model="edited_user.email"
                placeholder="Email address"
                required
              />
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label>Mobile Number</label>
              <input
                type="text"
                class="form-control"
                v-model="edited_user.mobile_number"
                placeholder="Mobile number"
                required
              />
            </div>
          </div>

          <div class="col-md-12" v-if="roles.length">
            <div class="form-group">
              <label>Assign Roles</label>

              <div class="checkbox" v-for="role in roles" :key="role.id">
                <label
                  ><input
                    type="checkbox"
                    v-model="edited_user.roles"
                    :value="role.id"
                  />
                  {{ role.display_name }}</label
                >
              </div>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EditUser",
  props: {
    user: Object,
  },
  data: () => {
    return {
      toastr: "",
      showToast: false,
      edited_user: {
        id: 0,
        emp_code: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        roles: [],
      },
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  methods: {
    onSubmitted() {
      this.$store
        .dispatch("Users/updateUser", this.edited_user)
        .then(() => {
          this.showToast = true;
          this.$toastr.s('success', "User successfully updated.", "User Updated");
          this.$emit("onEditedUser");
        })
        .catch((err) => {
          this.showDismissibleAlert = true;
          this.serverErrors = [];
          if (err.response.data.message) {
            this.serverErrors.push(err.response.data.message);
          }

          let errors = err.response.data.errors;
          for (let field of Object.keys(errors)) {
            this.showToast = true;
            this.$toastr.e("error, " + errors[field][0] + ", Error!");
          }
        });
    },
  },
  computed: {
    ...mapGetters("Roles", ["roles"])
  },
  mounted() {
    this.$store.dispatch("Roles/getRoles");
    this.edited_user = { ...this.user };
    this.edited_user.roles = this.user.roles.map((item) => item.id);
  },
};
</script>

<style scoped>
</style>
