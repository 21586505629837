<script>
import SearchAndActions from "@/components/Table/SearchAndActions.vue";
import Header from "@/components/Table/Header.vue";
import axios from "axios";
import DataTable from "@/components/Table/DataTable.vue";
import ActionsButton from "@/components/Table/ActionsButton.vue";
import TablePagination from "@/components/Table/TablePagination.vue";
import EditUser from "@/components/users/EditUser.vue";
import CreateUser from "@/components/users/CreateUser.vue";

export default {
  name: "Users",
  components: {CreateUser, EditUser, TablePagination, ActionsButton, DataTable, Header, SearchAndActions},
  data() {
    return {
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "Users",
        path: "/users",
        isActive: true
      }],
      tableData: [],
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 0,
        last_page: 0,
      },
      search: null,
      sort: '-created_at',
      date: null,
      dangerModal: false,
      email:"",
      name:"",
      user: {
        id: 0,
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        roles: [],
      },
      editUserModal: false,
      createUserModal: false,
    }
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  mounted() {
    let page = this.$route.query.page ? this.$route.query.page : 1;
    let per_page = this.$route.query.per_page ? this.$route.query.per_page : 10;
    let search = this.$route.query.search ? this.$route.query.search : null;
    let sort = this.$route.query.sort ? this.$route.query.sort : '-created_at';
    let date = this.$route.query.date ? this.$route.query.date : null;

    this.getListData(page, per_page, search, sort, date);
  },
  methods: {
    getListData(page = 1, per_page = 10, search = null, sort = '-created_at', date = null) {
      let url = '/admin/users-list';
      let params = {
        page: page,
        per_page: per_page ? per_page : this.pagination.per_page,
        search: search ? search : this.search,
        sort: sort,
        date: date ? date : this.date,
      };

      axios.get(url, {params})
          .then(response => {
            this.tableData = response.data.data;
            this.pagination = response.data.meta;
            this.sort = sort;

            this.$router.replace({query: params});
          })
    },
    onPageChange(page) {
      this.getListData(page, this.pagination.per_page, this.search, this.sort, this.date);
    },
    onPageSizeChanged(pageSize) {
      this.getListData(1, pageSize, this.search, this.sort, this.date);
    },
    onSearch(search) {
      this.search = search ? search : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    },
    sortBy(field) {
      let sort = this.sort === field ? '-' + field : field;
      this.getListData(1, this.pagination.per_page, this.search, sort, this.date);
    },
    onDate(date) {
      this.date = date ? date.join(',') : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    },
    resetPasswordModal(email,name){
      this.dangerModal = true;
      this.email = email;
      this.name = name;
    },
    resetToken() {
      this.axios
          .post("admin/users/forgot-password", { email: this.email })
          .then((res) => {
            if (res.data.Success) {
              this.message = "Password Reset Link has been sent to your email.";
              this.$toastr.s("success", "Password reset link sent to email.");
              this.dangerModal = false;
            }
          })
          .catch((err) => {
            console.log(err.response.data);
          });
    },
    editUser(user) {
      this.user = user;
      this.editUserModal = true;
    },
    dismissEditUserModal() {
      this.editUserModal = false;
    },
    dismissCreateUserModal() {
      this.createUserModal = false;
    },
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h6 class="text-uppercase font-weight-bold text-dark">Users</h6>
      </div>

      <div class="col-md-12">
        <div class="card p-4">
          <SearchAndActions
              :show-date="false"
              :show-import="false"
              @search="onSearch"
              @date="onDate"
              action-url="/admin/users-list"
              download-file-name="users"
          >

            <el-button
                v-if="checkUsrPermissions(['User_all', 'User_create'])"
                icon="el-icon-plus"
                type="primary"
                size="small"
                class="ml-2"
                @click.prevent="createUserModal = true"
            >
              Create User
            </el-button>
            <el-button
                v-if="checkUsrPermissions(['Role_all', 'Role_list'])"
                @click.prevent="$router.push('/roles')"
                native-type="button"
                icon="el-icon-setting"
                type="success"
                size="small"
                class="ml-2"
            >
              Manage Role
            </el-button>
          </SearchAndActions>

          <div class="row">
            <div class="col-md-12">
              <DataTable>
                <template #header>
                  <Header :sort="false" title="Sl"/>
                  <Header active="emp_code" :ordering="sort" @sortBy="sortBy('emp_code')"
                          title="Emp Code"/>
                  <Header active="first_name" :ordering="sort" @sortBy="sortBy('first_name')" title="Name"/>
                  <Header active="email" :ordering="sort" @sortBy="sortBy('email')" title="Email"/>
                  <Header active="mobile_number" :ordering="sort" @sortBy="sortBy('mobile_number')" title="Mobile Number"/>
                  <Header active="roles" :ordering="sort" @sortBy="sortBy('roles')" title="Role(s)"/>
                  <Header
                      :sort="false"
                      title="Actions"
                      v-if="
                          checkUsrPermissions([
                            'User_all',
                            'User_edit'
                          ])
                        "
                  />
                </template>

                <tr
                    v-for="(data, index) in tableData"
                    :key="data.id"
                >
                  <th scope="row">
                    {{ pagination.from + index }}
                  </th>
                  <td>
                    {{ data.emp_code }}
                  </td>
                  <td>
                    {{ data.first_name }} {{ data.last_name }}
                  </td>
                  <td>
                    {{ data.email }}
                  </td>
                  <td>
                    {{ data.mobile_number }}
                  </td>
                  <td>
                    <span
                        class="badge badge-primary role"
                        v-for="role in data.roles"
                        :key="role.id"
                    >{{ role.name }}</span
                    >
                  </td>

                  <td
                      v-if="
                          checkUsrPermissions([
                            'User_all',
                            'User_edit'
                          ])
                        "
                  >
                    <ActionsButton>
                      <a
                          href="#"
                          @click="resetPasswordModal(data.email, data.first_name+' '+data.last_name)"
                      >
                        Reset Password
                      </a>
                      <a
                          href="#"
                          @click.prevent="editUser(data)"
                      >
                        Edit User
                      </a>
                    </ActionsButton>
                  </td>
                </tr>
              </DataTable>

              <div class="mt-5" v-if="tableData.length">
                <TablePagination
                    :pagination="pagination"
                    @pagechanged="onPageChange"
                    @pagesizechanged="onPageSizeChanged"
                />
              </div>

              <div v-else>
                <p class="text-center font-weight-bold">
                  No data found
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CModal
        v-if="createUserModal"
        :closeOnBackdrop="false"
        title="Add User"
        color="primary"
        class="hide-footer custom-close-button"
        :show.sync="createUserModal"
    >
      <CreateUser @onSavedUser="dismissCreateUserModal"></CreateUser>
    </CModal>

    <CModal
        v-if="editUserModal"
        :closeOnBackdrop="false"
        title="Edit User"
        color="primary"
        class="hide-footer custom-close-button"
        :show.sync="editUserModal"
    >
      <EditUser @onEditedUser="dismissEditUserModal" :user="user"></EditUser>
    </CModal>

    <CModal title="Reset Password" :show.sync="dangerModal">
      <span
      >Are you sure you would want to reset password of <i class="font-weight-bold">{{name}}</i>?</span
      >
      <template slot="footer">
        <CButton @click="dangerModal = false" color="danger">Discard</CButton>
        <CButton @click="resetToken()" color="success">Reset Password</CButton>
      </template>
    </CModal>
  </div>
</template>